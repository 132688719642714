<template>
  <page :styleWrap="{background: '#fff'}" :state='pageState'>
    <div v-if='groupInfo'>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="spanH"></div>
      

        <div class="aravetPanel">
            <div class="groupTopImg">
                <img slot='img' style="width: 100px; height: 100px;" src="../../assets/groupManager.png" alt="">
            </div>
            
            <span class="groupTopTitle">群助手</span>

            <div class="groupTopDesc">
                <p>助手说明：</p>
                <p>1、助手是协助管理员管理工作 </p>
                <p>2、增加、移除工作人员。 </p>
                <p>3、设置看帖时间</p>
            </div>
        </div>

      <div class="spanH"></div>

      <div class="aravetPanel f_flex f_w">
        <!-- item -->
        <div class="infoItem f_flex f_a_c f_col" v-for='(item, index) in groupInfo.groupUserList' :key='index'>
          <div class="avatar">
            <img style="width: 100%; height: 100%; border-radius: 44px" :src="item.avatar" alt="">
            <div class="groupMaster theme_bg" v-if='item.roleCode=="GROUP_ADMIN"'>群主</div>
          </div>
          <div class="name">
            {{item.userName}}
          </div>
        </div>
        <!-- add -->
        <div class="infoItem f_flex f_j_c" @click='addGroupItemFn'>
          <div class="iconAdd  f_flex f_j_c f_a_c">
            <i class="iconfont icon-add"></i>
          </div>
        </div>
        <!-- delete -->
        <div class="infoItem f_flex f_j_c" @click='delGroupItemFn'>
          <div class="iconAdd f_flex f_j_c f_a_c">
            <i class="iconfont icon-jianhao"></i>
          </div>
        </div>
      </div>
      <div class="spanH"></div>
      <div class="closeGroup act_link" v-if='groupInfo.closeGroupStatus == 1' @click='closeGroupFn'>关闭群</div>
      <div class="spanH"></div>
    </div>
  </page>
</template>

<script>

import groupApi from '@/api/group'

export default {
  components: {

  },
  data: ()=> ({
    pageState: 'loading',
    groupInfo: null,
    rangeTimeType: ['', '全部', '最近半年', '自定义'],
  }),
  methods: {
    getInfo() {
      groupApi.groupManagerInfo({
        groupId: this.$route.query.groupId
      }).then(res => {
        if (res.data) {
          this.groupInfo = res.data
          this.pageState = 'success'
        } else {
          this.pageState = 'fail'
        }
      })
    },
    // 关闭群信息
    closeGroupFn() {
      this.$dialog.confirm({
        message: '是否关闭群？'
      }).then(()=> {
        groupApi.groupClose({
          groupId: this.$route.query.groupId
        }).then(res => {
          if (res.data) {
            this.$notify(res.data.msg)
            setTimeout(()=> {
              this.$router.back()
            }, 1000)
          }
        })
      }).catch(()=> {})
    },
    // 添加成员
    addGroupItemFn() {
      const {companyId, groupId} = this.$route.query
      this.$router.push(`/group/managerAdd?companyId=${companyId}&groupId=${groupId}&from=addGroupItem`)
    },
    delGroupItemFn() {
      const {companyId, groupId} = this.$route.query
      this.$router.push(`/group/managerDelete?companyId=${companyId}&groupId=${groupId}&from=delGroupItem`)
    },
    changeNameFn() {
      if (this.groupInfo.groupNameModifyStatus == 1) {
        this.$router.push(`/group/changeName?groupId=${this.$route.query.groupId}`)
      }
    },
    goGroupLabelFn() {
      this.$router.push(`/build/labelManage?groupId=${this.$route.query.groupId}`)
    },
    changeTimeFn() {
      if (this.groupInfo.postsTimeModifyStatus == 1) {
        this.$router.push(`/group/rangeTime?type=${this.groupInfo.showPostsEndTimeType}&groupId=${this.$route.query.groupId}`)
      }
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="less" scoped>
.radius {
  width: 44px;
  height: 44px;
  border-radius: 40px;
}
.spanH {
  height: 8px;
  background: #F6F6F6;
}
.aravetPanel {
  background: #fff;
  padding-top: 20px;
}
.groupMaster {
  position: absolute;
  right: -10px;
  bottom: -4px;
  border: 1px solid #fff;
  border-radius: 3px;
  font-size: 12px;
  transform: scale(0.75);
  padding: 2px 4px;
  box-sizing: border-box;
}
.infoItem {
  width: 20%;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 20px;
  .avatar {
    .radius();
    position: relative;
  }
  .iconAdd {
    .radius();
    color: #C8C8C8;
    font-size: 28px;
    font-weight: 500;
    border: 1px dashed #C8C8C8;
  }
  .name {
    font-size: 12px;
    margin-top: 3px;
    color: #2D2D2D;
  }
}
.closeGroup {
  font-size: 16px;
  color: #EA574C;
  padding: 12px 0;
  text-align: center;
}
.groupTopImg {
    display: flex;
    justify-content: center;
}
.groupTopTitle {
    margin-top: 10px;
     display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    color: #434343;
    line-height: 22px;
}
.groupTopDesc {
    margin-left: 15px;
    margin-top: 24px;
}
</style>